import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Toaster, toast } from 'sonner';
import { FaCircleXmark, FaCircleCheck, FaEye, FaEyeSlash } from 'react-icons/fa6';

const UpdateStudent = () => {
    const { id } = useParams(); // Fetching the ID from the URL params
    const formattedId = id.startsWith(':') ? id.substring(1) : id;
    const navigate = useNavigate();

    const [file, setFile] = useState(null);
    const [formData, setFormData] = useState({
        id: '',
        name: '',
        email: '',
        phone: '',
        street: '',
        postal_code: '',
        city: '',
        state: '',
        dob: '',
        image: '',
        fname: '',
        femail: '',
        fphone: '',
        fstreet: '',
        fpostal_code: '',
        fcity: '',
        fstate: '',
        paymentType: 'Cash',
        password: '',
        password_confirmation: ''
    });

    useEffect(() => {
        const fetchStudentData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/student/view/update/${formattedId}`);
                if (response.data.status && response.data.code === 200) {
                    const studentData = response.data.data;
                    setFormData({
                        id: studentData.id,
                        name: studentData.name,
                        email: studentData.email,
                        phone: studentData.phone,
                        street: studentData.street,
                        postal_code: studentData.postal_code,
                        city: studentData.city,
                        state: studentData.state,
                        dob: studentData.dob,
                        image: studentData.image,
                        fname: studentData.fname,
                        femail: studentData.femail,
                        fphone: studentData.fphone,
                        fstreet: studentData.fstreet,
                        fpostal_code: studentData.fpostal_code,
                        fcity: studentData.fcity,
                        fstate: studentData.fstate,
                        paymentType: studentData.paymentType,
                        password: '', // Assuming you do not fetch password from API for security reasons
                        password_confirmation: ''
                    });
                } else {
                    toast.error('Failed to load student data. Please try again.');
                }
            } catch (error) {
                toast.error('Failed to load student data. Please try again.');
            }
        };

        if (id) {
            fetchStudentData();
        }
    }, [id, formattedId]);

    const fileHandler = (e) => {
        setFile(e.target.files[0]);
    };

    const removeFile = () => {
        setFile(null);
    };

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const form = new FormData();
        Object.keys(formData).forEach(key => {
            form.append(key, formData[key]);
        });

        if (file) {
            form.append('image', file);
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/student/update/${id}`, form, {
                headers: {
                    'Content-Type': 'applications/json'
                }
            });

            toast.success(
                <div>
                    <FaCircleCheck style={{ color: 'green', marginRight: '10px' }} />
                    Student updated successfully
                </div>
            );

            setTimeout(() => {
                navigate('/student');
            }, 2000);
        } catch (error) {
            console.error('Error updating student:', error);
            toast.error(
                <div>
                    <FaCircleXmark style={{ color: 'red', marginRight: '10px' }} />
                    Failed to update student. Please try again.
                </div>
            );
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    return (
        <>
            <Toaster />
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="mb-0">Student Details</h5>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-xl-3 col-lg-4">
                                <label className="form-label text-primary">Photo<span className="required">*</span></label>
                                    <div className="avatar-upload">
                                        <div className="avatar-preview">
                                            <div
                                                id="imagePreview"
                                                style={{ backgroundImage: file ? `url(${URL.createObjectURL(file)})` : `url(${formData.image})` }}
                                            />
                                        </div>
                                        <div className="change-btn mt-2 mb-lg-0 mb-3">
                                            <input type='file' className="form-control d-none" onChange={fileHandler} id="imageUpload" accept=".png, .jpg, .jpeg" />
                                            <label htmlFor="imageUpload" className="dlab-upload mb-0 btn btn-primary btn-sm">Choose File</label>
                                            <button className="btn btn-danger light remove-img ms-2 btn-sm" onClick={removeFile}>Remove</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-9 col-lg-8">
                                    <div className="row">
                                        <div className="col-xl-6 col-sm-6">
                                            <div className="mb-3">
                                                <label htmlFor="name" className="form-label text-primary">Full Name<span className="required">*</span></label>
                                                <input type="text" className="form-control" id="name" value={formData.name} onChange={handleInputChange} placeholder="James William" />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label text-primary">Date & Place of Birth<span className="required">*</span></label>
                                                <div className="d-flex">
                                                    <input type="text" className="form-control" id="dob" value={formData.dob} onChange={handleInputChange} placeholder="yyyy-mm-dd" />
                                                    <input type="text" className="form-control w-50 ms-3" id="state" value={formData.state} onChange={handleInputChange} placeholder="State" />
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="email" className="form-label text-primary">Email<span className="required">*</span></label>
                                                <input type="email" className="form-control" id="email" value={formData.email} onChange={handleInputChange} placeholder="hello@example.com" />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="street" className="form-label text-primary">Address<span className="required">*</span></label>
                                                <textarea className="form-control" id="street" rows="6" value={formData.street} onChange={handleInputChange} />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-sm-6">
                                            <div className="mb-3">
                                                <label htmlFor="phone" className="form-label text-primary">Phone Number<span className="required">*</span></label>
                                                <input type="number" className="form-control" id="phone" value={formData.phone} onChange={handleInputChange} placeholder="+123456789" />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="postal_code" className="form-label text-primary">Postal Code<span className="required">*</span></label>
                                                <div className="d-flex">
                                                    <input type="text" className="form-control" id="city" value={formData.city} onChange={handleInputChange} placeholder="City" />
                                                    <input type="number" className="form-control w-60 ms-3" id="postal_code" value={formData.postal_code} onChange={handleInputChange} placeholder="426789" />
                                                </div>
                                            </div>
                                            <div className="mb-3 position-relative">
                                                <label htmlFor="password" className="form-label text-primary">
                                                    Password<span className="required">*</span>
                                                </label>
                                                <input
                                                    type={showPassword ? "text" : "password"}
                                                    className="form-control"
                                                    id="password"
                                                    value={formData.password}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter your password"
                                                />
                                                <span
                                                    className="position-absolute end-0 top-50 translate-middle-y pe-3 cursor-pointer"
                                                    onClick={togglePasswordVisibility}
                                                >
                                                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                                                </span>
                                            </div>

                                            <div className="mb-3 position-relative">
                                                <label htmlFor="password_confirmation" className="form-label text-primary">
                                                    Confirm Password<span className="required">*</span>
                                                </label>
                                                <input
                                                    type={showConfirmPassword ? "text" : "password"}
                                                    className="form-control"
                                                    id="password_confirmation"
                                                    value={formData.password_confirmation}
                                                    onChange={handleInputChange}
                                                    placeholder="Confirm your password"
                                                />
                                                <span
                                                    className="position-absolute end-0 top-50 translate-middle-y pe-3 cursor-pointer"
                                                    onClick={toggleConfirmPasswordVisibility}
                                                >
                                                    {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-12">
                <div className="card">
                    <div className="card-header">
                        <h5 className="mb-0">Parents Details</h5>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-xl-6 col-sm-6">
                            <div className="mb-3">
                                    <label htmlFor="fname" className="form-label text-primary">Parent Name<span className="required">*</span></label>
                                    <input type="text" className="form-control" id="fname" value={formData.fname} onChange={handleInputChange} placeholder="Mana William" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="femail" className="form-label text-primary">Email<span className="required">*</span></label>
                                    <input type="email" className="form-control" id="femail" value={formData.femail} onChange={handleInputChange} placeholder="hello@example.com" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="fstreet" className="form-label text-primary">Address<span className="required">*</span></label>
                                    <textarea className="form-control" id="fstreet" rows="6" value={formData.fstreet} onChange={handleInputChange} />
                                </div>
                            </div>
                            <div className="col-xl-6 col-sm-6">
                                <div className="mb-3">
                                    <label htmlFor="fphone" className="form-label text-primary">Phone Number<span className="required">*</span></label>
                                    <input type="number" className="form-control" id="fphone" value={formData.fphone} onChange={handleInputChange} placeholder="+123456789" />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label text-primary">State & City</label>
                                    <div className="d-flex">
                                        <input type="text" className="form-control" id="fstate" value={formData.fstate} onChange={handleInputChange} placeholder="State Name" />
                                        <input type="text" className="form-control w-60 ms-3" id="fcity" value={formData.fcity} onChange={handleInputChange} placeholder="City Name" />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="fpostal_code" className="form-label text-primary">Postal Code<span className="required">*</span></label>
                                    <input type="number" className="form-control" id="fpostal_code" value={formData.fpostal_code} onChange={handleInputChange} placeholder="426789" />
                                </div>
                                <label className="form-label text-primary">Payments<span className="required">*</span></label>
                                <div className="d-flex align-items-center">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="Cash"
                                            id="paymentType"
                                            checked={formData.paymentType === 'Cash'}
                                            disabled
                                        />
                                        <label className="form-check-label font-w500" htmlFor="flexCheckDefault">
                                            Cash
                                        </label>
                                    </div>
                                    <div className="form-check ms-3">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="Online"
                                            id="paymentType"
                                            checked={formData.paymentType === 'Online'}
                                            disabled
                                        />
                                        <label className="form-check-label font-w500" htmlFor="flexCheckDefault1">
                                            Online
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-3">
                            <button className="btn btn-primary" type="button" onClick={handleSubmit}>Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UpdateStudent;