import React, { lazy, Suspense, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Route, Routes, useNavigate, useParams, useLocation } from 'react-router-dom';
import { checkAutoLogin, isLogin } from './services/AuthService';
import { isAuthenticated } from './store/selectors/AuthSelectors';
import './other/swiper/css/swiper-bundle.min.css';
import "./other/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import TeacherLogin from './jsx/pages/TeacherLogin';
import StudentLogin from './jsx/pages/StudentLogin';
import UpdateStudent from './jsx/components/Student/UpdateStudent';

const Index = lazy(() => import('./jsx/index'));
const SignUp = lazy(() => import('./jsx/pages/Registration'));
const Login = lazy(() => new Promise(resolve => setTimeout(() => resolve(import('./jsx/pages/Login')), 500)));


function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return <Component {...props} router={{ location, navigate, params }} />;
    }
    return ComponentWithRouterProp;
}

function App(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        checkAutoLogin(dispatch, navigate);
    }, [dispatch, navigate]);

    let routeblog = (
        <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/student/login' element={<StudentLogin />} />
            <Route path='/teacher/login' element={<TeacherLogin />} />
            <Route path='/page-register' element={<SignUp />} />
            
            {/* <Route path="/update-student/id" element={<UpdateStudent />} /> */}
        </Routes>
    );

    if (props.isAuthenticated) {
        return (
            <Suspense fallback={
                <div id="preloader">
                    <div className="sk-three-bounce">
                        <div className="sk-child sk-bounce1"></div>
                        <div className="sk-child sk-bounce2"></div>
                        <div className="sk-child sk-bounce3"></div>
                    </div>
                </div>
            }>
                <Index />
            </Suspense>
        );
    } else {
        return (
            <div className="vh-100">
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                }>
                    {routeblog}
                </Suspense>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
    };
};

export default withRouter(connect(mapStateToProps)(App));
